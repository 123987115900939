/*
 * @Description: 关联任务单表头
 * @Author: ChenXueLin
 * @Date: 2021-10-15 14:30:10
 * @LastEditTime: 2022-05-26 15:31:46
 * @LastEditors: ChenXueLin
 */
/**关联任务单表头 */
export const bindTaskColumnData = [
  {
    fieldName: "workOrderId",
    display: true,
    fieldLabel: "工单ID",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workNo",
    display: true,
    fieldLabel: "任务单号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  // {
  //   fieldName: "taskSourceNo",
  //   display: true,
  //   fieldLabel: "来源单号",
  //   width: 160,
  //   disabled: false,
  //   fixed: false,
  //   align: "center"
  // },
  {
    fieldName: "corpName",
    display: true,
    fieldLabel: "客户名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "taskFirstClassName",
    display: true,
    fieldLabel: "工单类型",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workOrderStatusName",
    display: true,
    fieldLabel: "工单状态",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  // {
  //   fieldName: "expectTime",
  //   display: true,
  //   fieldLabel: "期望上门时间",
  //   width: 160,
  //   disabled: false,
  //   fixed: false,
  //   align: "center"
  // },
  {
    fieldName: "areaName",
    display: true,
    fieldLabel: "所属地区",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
/**项目仓设备表头 */
//未安装设备表头
export const unInstallColumnData = [
  {
    fieldName: "thirdClassName",
    display: true,
    fieldLabel: "商品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "firstClassName",
    display: true,
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "secondClassName",
    display: true,
    fieldLabel: "商品开票名称",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "equipNo",
    display: true,
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//已安装设备表头
export const installColumnData = [
  {
    fieldName: "thirdClassName",
    display: true,
    fieldLabel: "商品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "firstClassName",
    display: true,
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "secondClassName",
    display: true,
    fieldLabel: "商品开票名称",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "equipNo",
    display: true,
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "engineerName",
    display: true,
    fieldLabel: "安装操作人",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "installDate",
    display: true,
    fieldLabel: "安装时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
/**项目人员表头 */
export const projectPersonColumnData = [
  {
    fieldName: "userId",
    display: true,
    fieldLabel: "用户ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "employeeName",
    display: true,
    fieldLabel: "用户名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "phone",
    display: true,
    fieldLabel: "联系电话",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "roleNameList",
    display: true,
    fieldLabel: "用户角色",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "userStatusName",
    display: true,
    fieldLabel: "用户状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "frameWorkName",
    display: true,
    fieldLabel: "所属组织",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "areaNames",
    display: true,
    fieldLabel: "所属地区",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
